.other {
  font-family:'Source Sans 3';
  font-size: 24px;
}

.otherTitle {
  font-size: 24px;
  font-family:'Source Sans 3';
  padding-bottom: 10px;
  padding-left: 30px;
}

.back-btn {
  cursor: pointer;
  padding: 1em;
  left: 0;
}

.otherCards {
  padding: 2rem;
  /*padding-left: 4rem;
  padding-right: 4rem;*/
  /*padding-top: 2rem;*/
}

.otherCards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  /*max-width: 1120px;*/
  width: 100%;
  margin: 0 auto;
}

.otherCards__wrapper {
  position: relative;
}

.otherCards__items {
  margin-bottom: 24px;
  flex-direction: row;
  border-radius: 8px;
}

.otherCards__item {
  display: flex;
  /*margin: 1rem;*/
  padding: 20px;
  border-radius: 5px;
}

.otherCards__item__info {
  padding: 20px 20px 20px;
  width: 600px;
}

.otherCards__item__info2 {
  padding: 20px 20px 20px;
  /*width: 400px;*/
  border-radius: 8px;
}

.otherCards__nl__info {
  margin-top: 6em;
  padding: 0 20px;
  width: 600px;
}

.otherCards__item__text {
  color: #262626;
  font-size: 16px;
  line-height: 24px;
}

.other_img {
	margin-right: 30px;
	width: 70px;
}

.nl_art {
  width: 150px;
  margin-right: 20px;
}

.nl_art:hover {
  -ms-transform: scale(4.0); /* IE 9 */
  -webkit-transform: scale(4.0); /* Safari 3-8 */
  transform: scale(4.0); 
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.netlight {
  max-height: 260px !important;
  width: auto;
}

.NLtext {
  margin-bottom: 12em;
  /*font-size: 16px !important;*/
}

.img-menu {
  display: none;
  flex-direction: column;
  max-width: 400px;
  height: auto;
  position: absolute;
  top: 80px;
  opacity: 1;
  transition: all 0.5s ease;
}

.img-menu.active {
  display: flex;
  justify-content: center;
  background: #E4F2F2;
  left: 0;
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 1;
}

.img-modal {
  position: fixed;
  z-index: 1;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
}

.work-right {
  position: absolute;
  top: 50%;
  left: 100%;
  font-size: 1rem;
  color: #262626;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.work-left {
  position: absolute;
  top: 50%;
  right: 100%;
  font-size: 1rem;
  color: #262626;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.otherCards__item__text a:hover {
  color: #087C61; 
  transition: 0.3s ease-out;
}

.studs:hover {
  color: #087C61 !important;
}

@media only screen and (max-width: 820px) {
  .NLtext {
    margin-bottom: 6em;
  }
}

@media only screen and (max-width: 960px) {
  .otherCards__items {
    margin-bottom: 24px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

}

@media only screen and (max-width: 1200px) {

  .otherCards__items {
    margin-bottom: 24px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media only screen and (min-width: 1024px) {
  .otherCards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .otherCards__item {
    margin-bottom: 2rem;
    /*width: 600px;*/
  }

  .otherCards__item__info, .otherCards__item__info2 {
    width: 600px;
  }

  .work-right {
    left: 105%;
  }

  .work-left {
    right: 105%;
  }
}

@media screen and (max-width: 414px) {
  .otherCards__item__info, .otherCards__item__info2 {
    overflow: hidden;
  }

  .otherCards__item {
    margin-bottom: 2rem;
    width: 414px;
    /*width: 100%;*/
  }

  p, a, h5 {
  font-family:'Source Sans 3';
  font-weight: 400;
}

  .otherCards__item__text {
    font-size: 20px;
    line-height: normal;
  }

  .labelTitle {
    font-size: 26px !important;
  }

  .netlight {
    max-width: 380px;
    height: auto;
}

  .work-right {
    left: 100%;
    font-size: 20px;
    color: #49a6a6;
  }

  .work-left {
    right: 100%;
    font-size: 20px;
    color: #49a6a6;
  }

  .NLtext {
    padding-top: 24em;
    padding-left: 2em;
    width: auto;
    font-size: 18px !important;
  }

  .otherCards__nl__info {
    padding: 0 20px;
    width: auto;
    height: 22em; /*340px*/
  }

  .back-btn {
    padding: 0;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) 
{ _::-webkit-full-page-media, _:future, 

  .NLtext {
    margin-top: 14em;
    width: 100%;
    /*font-size: 14px !important;*/
  }
}