.dot {
	padding: 3px;
    margin-right: 5px;
    cursor: pointer;
    border-radius: 50%;
}

.dots {
	position: absolute;
    bottom: -33px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}