.skillCards {
  padding: 2rem;
  }

.labelTitle {
  font-size: 20px;
  font-family:'Source Sans 3';
  padding-bottom: 10px;
  letter-spacing: 0.95px;
  word-spacing: 1.4px;
  line-height: 1.2;
  font-weight: 700;
  text-align: left;
}

.eduTitle {
  font-family:'Source Sans 3';
  font-weight: 700;
  font-style: normal;
  color: #262626;
  width: inherit;
}

.skillCards__item {
  display: flex;
  margin: 0 1rem;
  margin-bottom: 1rem;
  justify-content: center;
}

.skillCards__item__info {
  padding: 20px 20px 20px;
  width: 300px;
  border-radius: 8px;
}

.skillCards__item__text {
  color: #262626;
  font-size: 16px;
  line-height: 24px;
}

.title {
  margin-bottom: 1em;
  margin-top: revert;
}

.skillCards__items {
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: left;
  margin-top: auto;
  margin-top: 2em;
}

.skillGroup {
  display: flex;
  justify-content: center;
}

.flag {
  font-size: 20px;
}

@media only screen and (max-width: 1024px) {
  .labelTitle {
  font-size: 18px; /*32px*/
  }
}

@media only screen and (min-width: 768px) {
  .skillCards__items {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 768px) {
  .skillCards__items {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 414px) {
  .skillGroup {
      flex-direction: column;
      justify-content: center;
  }

  .skillCards__items {
    display: flex;
  }

  .skillCards__item {
    margin-bottom: 2em;
    width: 400px;
  }

  .skillCards__item__info {
    width: auto; /*20em*/
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 2em;
  }

  .title {
    font-size: 28px !important;
  }

  .other-skill {
    display: block;
  }
   .skillCards__item__text p {
    min-width: 18em;
  }

  .skillCards__item__text p {
    font-size: 24px !important;
    line-height: 1.2em;
  }
}

/* Portrait iPhone */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 
    .skillGroup {
      flex-direction: column;
      justify-content: center;
    }

    .skillCards__items {
      display: flex;
      justify-content: center;
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) 
{ _::-webkit-full-page-media, _:future, 
  .eduTitle {
  font-family:'Source Sans 3';
  font-weight: 600;
  }

  .otherCards__item__info {
    max-width: 600px;
  }
}