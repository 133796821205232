*, *:before, *:after { box-sizing: border-box; }

.image-gallery-container{
  margin: 2rem 0;
  padding: 0;
  text-align: center;
}

.image-gallery-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 8px;
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  
  @include bp( x-small ) {
    grid-template-columns: repeat(1, 1fr);
  }	  
  @include bp( small ) {
    grid-template-columns: repeat(1, 1fr);
  }
  @include bp( large ) {
    grid-template-columns: repeat(1, 1fr);
  }	

  img {
    width: 100%;
  }
  
}

.image-gallery-img {
    width: 100%;
  }

.image-gallery-grid div {
  position: relative;
  cursor: pointer;
  align-self: center;
}

.image-modal {
  position: fixed;
  z-index: 1000; /*999*/
  width: 90%; /*70%*/
  max-width: 800px;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  @include bp( x-small ) {
    width: 95%;
  }	  
  @include bp( small ) {
    width: 80%;
  }	
  @include bp( large ) {
    width: 60%;
  }
}

.image-modal-overlay {
  position: fixed;
  z-index: 1000; /*1*/
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  top: 0;
  left: 0;
}

.image-modal-body a {
  position: absolute;
  display: inline;
  color: #fff;
  text-decoration: none;
  line-height: 36px;
  font-size: 40px;
  height: 40px; width: 40px;
  text-align: center;
}

.image-modal-body .image-modal-close {
  right: 0; top: 0;
  border-radius: 0 0 0 5px;
}

.image-modal-body {
  position: relative;
}

.image-gallery-img-open {
  max-height: 40em; /*600px*/
  max-width: -webkit-fill-available;
  max-width: -moz-available;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.img_caption {
  color: #fff;
}

@media screen and (max-width: 414px) {
  .image-gallery-img-open {
  width: 100%;
  height: 100%;
  object-fit: contain;
  }

  .image-modal {
    width: 100%; /*300px*/
    padding: 0 2em;
  }

  .image-gallery-grid {
    width: 100%;
    padding: 0 1rem;
  }

  .img_caption {
    font-size: 18px;
  }

  .image-modal-body a {
    position: absolute;
    display: inline;
    color: black;
    text-decoration: none;
    line-height: 36px;
    background: #fff; /*#E4F2F2*/
    border-radius: 5px;
    height: 40px; width: 40px;
    font-size: 40px;
    text-align: center;
  }
}

@media screen and (max-width: 820px) {
  .image-modal-body{
  position: relative;
  width: -webkit-fill-available;
}

  .image-gallery-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .image-gallery-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}