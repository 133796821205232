.about-me-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(/public/images/squiggle.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: absolute;
  background-attachment: fixed;
  background-position: right top;
  z-index: 0;
  height: 100vh; /*1vh = 1% of viewport height*/
}

.about-links {
  width: 90%;
  display: flex;
  justify-content: center;
}

.about-link-wrapper {
  display: flex;
  flex-direction: row;
}

.about-link-items, .about-link-items-text-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 2em;
  margin-right: 2em;
  margin-bottom: 0;
  margin-top: 50px;
  box-sizing: border-box;
  color: #262626;
}

.about-link-items-text-block {
  max-width: 900px;
}

.about-link-items > h2 {
  color: #fff;
}

.nl-link:hover {
  color: #9d8dff !important;
  transition: 0.3s ease-out;
}

.formulate-link:hover {
  transition: 0.3s ease-out;
}

.about-link-items a:hover {
  color: #087C61;
  transition: 0.3s ease-out;
}

.about-img {
	margin-top: 80px;
  right: 0px;
	/*margin-left: 20px;*/
  /*z-index: -1;
  position: fixed;*/
  /*margin-right: 8em;*/
}

.cv, .linkedin {
  font-weight: bold;
  background-color: #087C61;
  color: #ffffff !important;
  padding: 1em 0;
  padding-left: 2.5em;
  padding-right: 2.5em;
  text-decoration: none;
  font-size: 14px;
  margin-right: 1vw;
  border-radius: 4px;
}

.cv:hover, .linkedin:hover {
  filter: brightness(0.70);
  color: #fff;
}

.linkedin {
  background-color: #3b5998;
  color: #ffffff !important;
  padding: 1em 1.5em;
}


.txt {
  margin-top: 1.8em;
  width: cover;
}

.link-btns {
  margin-top: 40px;
}

@media screen and (max-width: 1024px) {

  .about-me-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(/public/images/squiggle.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: absolute;
  background-attachment: fixed;
  background-position: right top;
  z-index: 0;
}
  .me {
    order: 1;
    margin: auto;
    width: 400px;
  }

  .txt {
    order: 2;
  }

  .about-link-wrapper {
    flex-direction: column;
    width: -webkit-fill-available;
  }
 
}

@media screen and (max-width: 820px) {

  .about-links {
    padding-top: 2rem;
  }

  .about-link-wrapper {
    flex-direction: column;
    width: -webkit-fill-available;
  }

  iframe {
  width: -webkit-fill-available;
  }

  .about-img {
  margin-top: 0;
  }
}

@media screen and (max-width: 768px) {

  .about-links {
    padding-top: 2rem;
  }

  .about-link-wrapper {
    flex-direction: column;
    width: -webkit-fill-available;
  }

  iframe {
  width: -webkit-fill-available;
  }

  .about-img {
  margin-left: 20px;
  position: relative;
  }
}

@media screen and (max-width: 414px) {
.about-me-container {
  background-image: url(/public/images/squiggle.png);
  background-size: cover;
  margin: 0;
}

.link-btns {
  margin-top: 40px;
  order: 2;
}

.about-link-items p {
  font-size: 20px;
}

  .about-links {
    padding-top: 2rem;
    height: 100%;
  }

  .about-link-wrapper {
    flex-direction: column;
  }

  .about-link-items, .play-link {
    width: 100%;
    margin: 0;
    line-height: 1.6;
  }

  .play-link {
    padding-bottom: 2em;
    margin-top: -1em;
  }

  .me, .about-img {
    order: 1;
    margin: 0;
    width: 100%;
    height: auto;
  }

  .txt {
    order: 2;
    margin-left: 0;
  }

  iframe {
    width: -webkit-fill-available;
    max-width: 380px;
    justify-content: center;
    margin-bottom: 2em;
  }

  .title {
    font-size: 34px !important;
  }
}