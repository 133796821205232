.cards {
  padding: 4rem;
}

h1 {
  text-align: center;
}

.cards__container {
  margin: 0 auto
}

.cards__wrapper {
  position: relative;
}

.cards__items {
  margin-bottom: 15px;
  display: flex; /*inline-grid*/
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 2em;
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  width: 200px;
  flex-direction: row;
  width: 100%;
  /*box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));*/
  overflow: hidden;
  text-decoration: none;

  border-radius: 8px;
  background: var(--System-background-fr-color-bg, #FFF);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 0px 6px 0px rgba(0, 0, 0, 0.04), 0px 0px 24px 0px rgba(0, 0, 0, 0.06), 0px 0px 0px 1px #F7F8F8 inset;
}

.cards__item__link {
  margin-top: auto;
  font-weight: 600;
}

.cards__item__link:hover {
  color: #087C61;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  height: 350px;
  overflow: hidden;
}

.tags {
  font-size: 14px !important;
  font-weight: 600;
  margin-bottom: 1em;
  color: #097C61;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 350px;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}
.cards__item__info {
  padding: 20px 20px 20px;
  display: flex;
  flex-direction: column;
  /*justify-content: space-between;*/
  width: -webkit-fill-available;
}

.cards__item__text {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 1em;
}

.item_summary {
  text-align: left;
}

/* Portrait iPhone */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 

    .cards__items {
      flex-direction: column;
      justify-content: center;
      display: flex;
    }

    .cards__item {
       width: 90%;
       justify-content: center;
    }
}

@media screen and (max-width: 414px) {
  
  .cards {
    padding: 2rem;
  }

  #root, .cards__item, .cards__item__img {
    width: fit-content;
    /*width: 100%*/

  }

  .cards__item {
    flex-direction: column;
    display: flex;
  }

  .cards__items {
    flex-direction: column;
    justify-content: center;
    display: flex;
  }

  .cards__item__text {
    font-weight: 600;
    font-size: 24px !important;
    line-height: 24px;
  }

  .item_summary, .tags {
    font-size: 20px !important;
  }

  .cards__item__link {
    margin-top: 1em;
    font-size: 20px !important;
  }
}

@media screen and (min-width: 500px) and (max-width: 850px) /*(max-width: 768)*/{
  .cards__items {
      flex-direction: column;
      display: grid;
      grid-template-columns: repeat(1, auto);
    }

    .cards__item {
      width: 100%;
    }
}

@media only screen and (min-width: 960px) {
  .cards__items {
    display: flex;
  }

  }

@media only screen and (min-width: 960px) and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) 
{ _::-webkit-full-page-media, _:future, 
   .cards__item__text {
    font-size: 15px;
    font-weight: 600;
    width: 100%;
    }
  }