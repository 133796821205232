
.footer-container {
  background-color: #FAD167;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 15px;
}

h4 {
  color: #262626;
}

.footer-link-items a {
  color: #262626;
}

.footer-link-items:hover {
  color: #087C61;
  transition: 0.3s ease-out;
}

.website-rights {
  color: #262626;
  margin-bottom: 16px;
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }

  .footer-link-wrapper {
    flex-direction: column;
  }

  .social-media-wrap {
    flex-direction: column;
  }
}

@media screen and (max-width: 414px) {
  .footer-container {
    justify-content: center;
    font-size: 20px;
  }
}