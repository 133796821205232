*, *:before, *:after { box-sizing: border-box; }

.gallery-container {
  padding: .9375rem 0;
}

.gallery-container h1 {
  margin: 2rem 0;
  padding: 0;
  text-align: center;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 8px;
  width: 100%;
  margin: 0 auto;
  padding: 0 5rem;
  
  @include bp( x-small ) {
    grid-template-columns: repeat(1, 1fr);
  }	  
  @include bp( small ) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include bp( large ) {
    grid-template-columns: repeat(3, 1fr);
  }	

  img {
    width: 100%;
  }
  
}

.ui-gallery-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 8px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  
  @include bp( x-small ) {
    grid-template-columns: repeat(1, 1fr);
  }   
  @include bp( small ) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include bp( large ) {
    grid-template-columns: repeat(3, 1fr);
  } 

  img {
    width: 100%;
  }
  
}

.gallery-img {
    width: 100%;
  }

.ui-gallery-grid div, .gallery-grid div {
  position: relative;
  cursor: pointer;
  align-self: center;
}

.modal {
  position: fixed;
  z-index: 1000; /*999*/
  width: 90%; /*70%*/
  max-width: 800px;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  @include bp( x-small ) {
    width: 95%;
  }	  
  @include bp( small ) {
    width: 80%;
  }	
  @include bp( large ) {
    width: 60%;
  }	

  /*img, .gallery-img {
    width: auto;
  }*/
}

.title {
  font-size: 40px;
  font-family:'Source Sans 3';
  font-weight: 600;
  letter-spacing: 0.95px;
  word-spacing: 1.4px;
}

.modal-overlay {
  position: fixed;
  z-index: 1000; /*1*/
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  top: 0;
  left: 0;
}

.modal-body a, .ui-modal-body a {
  position: absolute;
  display: inline;
  color: #fff;
  text-decoration: none;
  line-height: 36px;
  font-size: 40px;
  width: 40px;
  text-align: center;
}

.modal-body .modal-close, .ui-modal-body .modal-close {
  right: 0; top: 0;
  border-radius: 0 0 0 5px;
}

.modal-body .modal-next,
.modal-body .modal-prev {
  right: 0; top: calc(50% - 25px);
  border-radius: 5px 0 0 5px;
  height: 50px;
  line-height: 40px;
  font-size: 60px;
}

.modal-body .modal-prev {
  left: 0;
  right: auto;
  border-radius: 0 5px 5px 0;
}

.modal-body {
  position: relative;
}

.ui-modal-body {
  position: relative;
  min-height: 400px;
}

.gallery-img-open {
  max-height: 40em; /*600px*/
  max-width: -webkit-fill-available;
  max-width: -moz-available;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.ui-gallery-img-open {
  /*max-height: 45em;*/ /*1000px*/
  max-width: -webkit-fill-available;
  max-width: -moz-available;
  max-width: fill-available;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.img_caption {
  color: #fff;
}

@media screen and (max-width: 414px) {
  .gallery-img-open {
  width: 100%;
  height: 100%;
  object-fit: contain;
  }

  .modal {
    width: 100%; /*300px*/
    padding: 0 2em;
  }

  .gallery-grid {
    width: 100%;
    padding: 0 1rem;
  }

  .modal-body .modal-next,
  .modal-body .modal-prev {
    right: 0; top: calc(50% - 50px);
  }

  .img_caption {
    font-size: 18px;
  }

  .modal-body a, .ui-modal-body a {
  position: absolute;
  display: inline;
  color: black;
  text-decoration: none;
  line-height: 36px;
  background: #fff; /*#E4F2F2*/
  border-radius: 5px;
  height: 40px; width: 40px;
  font-size: 40px;
  text-align: center;
  }
}

@media screen and (max-width: 820px) {
  .modal-body, .ui-modal-body {
  position: relative;
  width: -webkit-fill-available;
}

  .gallery-grid, .ui-gallery-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .gallery-grid, .ui-gallery-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}