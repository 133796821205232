.navbar {
  background: #fff !important;
  height: fit-content; /*3.2em*/
  display: flex;
  /*font-size: 1.2rem;*/
  position: sticky;
  top: 0;
  z-index: 999;
  margin-top: 0.1em;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /*max-width: 1500px;*/
  padding: 10px 0;
}

.navbar-logo {
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 66vw; /*50vw, 60vw*/
  justify-content: right;
  /*margin-right: 2rem;*/
}

.nav-item {
  font-size: 16px;
}

.nav-links {
  color: #262626;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  cursor: pointer;
}

.nav-links:hover {
  color: #087C61;
  transition: all 0.2s ease-out;
  text-decoration: underline;
}

.fa-bars {
  color: #262626;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.title-name {
  font-size: 16px;
  padding-right: 1.25rem;
  width: 385.59px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.logo:hover {
  transform: scale(1.1);
}

.section-tabs {
  float: right;
}

@media screen and (min-width: 960px) and (max-width: 1250px) {
  .navbar-container {
    padding: 1em;
  }

  .nav-menu {
    width: 50vw;
}
}

@media screen and (min-width: 960px) and (max-width: 1060px) {
  .title-name {
    font-size: 18px;
  }

  .navbar-container {
    padding: 1em;
  }
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .navbar-container {
    padding: 1em;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    /*height: 90vh;*/
    height: 114vh;
    position: absolute;
    top: 83px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #FAD167;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    font-size: 24px;
  }

  .nav-links:hover {
    color: #242424;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 20%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #262626;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    /*width: 80%;*/
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }

  .title-name {
    font-size: 18px;
    color: #262626; 
  }

  .navbar-logo {
    margin-top: 0;
    margin-left: auto;
    display: contents;
  }
}

@media screen and (max-width: 414px) {
  .title-name {
    /*width: 300px;*/
    /*width: auto; */
    font-size: 18px;
    /*line-height: 1.2;*/
  }
  .navbar {
    width: 100% !important;
    padding: 0 5px;
  }

  .navbar-logo {
    margin-top: -0.9em !important;
  }

  .menu-icon {
    transform: translate(-100%, 70%)
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
  .navbar {
    /*width: 422px;*/
    width: 100% !important;
    padding: 0 5px;
    height: auto;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) 
{ _::-webkit-full-page-media, _:future, 
  h1 {
    font-size: 30px !important;
  }

  .navbar-logo {
    margin-top: -0.4em;
  }
}