
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-style: normal;
  color: #03363D; /*#262626*/
  font-family: 'Source Sans 3';
}

body {
  background-color: #F7F8F8;
}

html {
  scroll-padding-top: 85px;
}

p {
  margin-bottom: 1em;
}

.home {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

iframe {
  width: 640px;
  height: 480px;
}

h1, .other {
  font-family: 'Source Sans 3';
  font-weight: 600;
  font-size: 40px;
  letter-spacing: 0.95px;
  word-spacing: 1.4px;
}

.other_item {
  width: 350px;
}

.img-wrapper {
  overflow: hidden;
}

img.hover-zoom {
  transition: all 0.3s ease 0s;
  width: 100%;
}
img.hover-zoom:hover {
  transform: scale(1.25);
}

.about-link-items-text-block a:hover {
  color: #087C61;
  transition: 0.3s ease-out;
}

/*IMAGE SLIDER*/

.slider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  min-width: 300px;
}

.image {
  width: auto;
  height: 400px;
}

.glow {
  border-radius: 10px;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  width: auto;
  height: 400px;
}

.greenglow {
  border-radius: 10px;
  box-shadow: 0 6px 20px rgba(22, 200, 155, 0.2);
  width: auto;
  height: 400px;
}

.img-text {
  opacity: 0;
  position: absolute;
  display: flex;
  min-width: 400px;
  justify-content: center;
  align-items: center;
  margin-top: 42em; /*38em*/
  font-size: 14px !important;
}

.img-text.active {
  opacity: 1
}

.fa-arrow-right {
  position: absolute;
  top: 50%;
  left: 105%;
  font-size: 1rem;
  color: #262626;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.fa-arrow-left {
  position: absolute;
  top: 50%;
  right: 105%;
  font-size: 1rem;
  color: #262626;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.arrows {
  position: relative;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}
/*.fa {
  padding: 1em;
  font-size: 1.039em;
  text-decoration: none;
  background: #3b5998;
  transition: all .5s ease;
  text-align: center;
  color: #fff !important;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}*/

.technologies {
   margin-bottom: 10px; 
   color: #097C61;
   font-weight: 500;
}

.play-link a:hover {
  color: #087C61 !important;
}

.wedding-img:hover, .wedding-img:active {
    transform:scale(1.5, 1.5);
}

@media screen and (max-width: 820px) {
  .img-text {
  margin-top: 35em;
  min-width: 350px;
  }

  .technologies {
    font-family:'Source Sans 3';
    font-weight: 500; /*600*/
  }

  .fa-arrow-right {
  right: 30%;
  }

  .fa-arrow-left {
    left: 30%;
    }

  p {
    font-weight: 400;
  }
}

@media screen and (max-width: 768px) {
  .img-text {
  margin-top: 35em;
  min-width: 350px;
  }

  .technologies {
    font-family:'Source Sans 3';
    font-weight: 500;
  }

   .fa-arrow-right {
  right: auto;
  }

  .fa-arrow-left {
    left: auto;
  }
}

.wedding-img {
  width: 480px;
}

.wedding_img_container {
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 2em;
}

.projectCard {
  margin: 0 auto;
  width: 30em;
  margin-top: 4em;
}

/* Portrait iPhone */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 
    .bedtimePDF {
      display: none;
    }

    .perceptionPDF {
      max-width: 370px;
      height: auto;
      position: relative;
    }

    .projectCard {
      margin: 0 auto;
      width: 20em;
      margin-top: -1em;
    }

    .wedding-img {
      width: 330px; /*95*/
    }

    .wedding_img_container {
      max-width: 380px; /*200*/
  }
}

@media screen and (max-width: 414px) {

  html {
    scroll-margin-top: 80px;
  }

  .double {
    max-width: 400px;
    height: auto;
  }

  .bedtimePDF, .perceptionPDF {
    display: none;
  }

  .projectCard {
    margin: 0 auto;
    width: 20em;
    margin-top: -1em;
  }

  .guidelines {
    margin-top: 1em;
    width: 21em;
  }

  .wedding-img {
    width: 330px; /*95*/
  }

  .wedding_img_container {
    justify-content: center;
    max-width: 380px;
  }

  .img-text {
    padding-bottom: 1em;
    min-width: 380px;
    font-size: 16px !important;
    padding-top: 22em;
    justify-content: center !important;
    display: block;
  }

  .technologies {
    font-family:'Source Sans 3';
    font-weight: 500;
  }

 .fa-arrow-right {
    left: 100%;
    font-size: 22px;
  }

  .fa-arrow-left {
    right: 100%;
    font-size: 22px;
  }

  .slider {
    max-width: 414px;
    justify-content: center;
  }

  p {
    font-weight: 400;
    /*font-size: 16px;*/
  }

  .hero-container, .intro-text {
    min-width: 370px;
    height: auto;
  }

  iframe {
    margin: 0 1em;
    
  }

  .about-container {
    justify-content: center;
    display: flex;
    margin: 0;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) 
{ _::-webkit-full-page-media, _:future, 

  .img-text { 
    margin-top: 20em; 
  }
  
  .fa-arrow-right {
  right: auto; 
  }

  .fa-arrow-left {
    left: auto;
  }

  .title {
    font-size: 24px;
  }
}