.hero-container {
  background-color: #FAD167;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  object-fit: contain;
  padding: 4em 0;
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
}

.me-img {
  position: left;
}

.start-title {
  /*font-family: 'Opake Heavy', 'Modak', cursive;*/
  font-family: 'Source Sans 3';
  font-weight: 600;
  margin-top: auto;
  text-align: left;
}

.text-block {
  padding: 20px;
  margin-left: 2em;
  width: 38em;
  font-size: 14px;
  font-family:'Source Sans 3';
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}

.text-block a:hover, Link:hover {
  color: #097C61;
  transition: 0.3s ease-out;
}

.about-link {
  font-weight: 600;
}

@media screen and (min-width: 960px) and (max-width: 1140px) {

  .text-block {
    left: 10vw;
  }
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }

  .me-img {
  display: none;
  }

  .text-block {
    position: inherit;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .text-block {
    /*width: 400px;*/
    margin: auto;
    position: inherit;
  }
}

@media screen and (max-width: 414px) {

  .text-block {
    line-height: 1.6;
    width: 100%;
  }

  .hero-container {
    margin-bottom: 1.25em;
    scroll-margin-top: initial;
    height: auto;
  }

  .about-link {
    font-size: 20px;
    font-weight: 600;
  }

  .intro-text {
    font-size: 20px;
  }

  .hero-container {
    padding: 2em 0;
  }

}

@media only screen and (-webkit-min-device-pixel-ratio: 3) {
    .text-block {
    line-height: 1.6;
  }

  .hero-container {
    /*margin-top: 2.5em;*/
    margin-bottom: 1.25em;
  }
}